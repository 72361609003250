@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,700&family=Playfair+Display:wght@900&display=swap');body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background {
  background-color: #EEEEEE;
}

h1 {
  font-family: 'Playfair Display';
  /* src: local('PlayfairDisplay'), url(../fonts/PlayfairDisplay-VariableFont_wght.ttf) format('truetype'); */
  /* font-weight: 900; */
}

h2 {
  font-family: "Playfair Display";
  /* src: local('LatoBoldItalic'), url(../fonts/Lato-Black.ttf) format('truetype'); */
}

h3 {
  color: #6C6C6C;
}

.col {
  flex: auto;
  font-family: "Lato";
  color: #6C6C6C;
}

body {
  font-family: "Lato";
  color: #6C6C6C;
}

p {
  color: #6C6C6C;
}

a {
  color: white !important; 
  text-decoration: none;
}

a:hover {
  color: #6C6C6C;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttons {
  background-color: #6C6C6C !important;
  border-color: #6C6C6C !important;
  text-transform: uppercase;
  font-family: "Playfair Display";
  color: white !important;
  margin-top: 20px;
}

.buttons:hover {
  background-color: transparent !important;
  border-color: #6C6C6C !important;
  color: #6C6C6C !important;
}

.links {
  color: white !important;
  text-decoration: none !important;
  font-family: "Lato"
}

.links:hover {
  color: #6C6C6C !important;
}

