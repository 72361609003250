.TravelContainer-Info {
    border-style: none solid none none;
    display: flex;
    justify-content: start;
    width: 25%;
}

.TravelContainer-Row{ 
    width: 100%;
}

.TravelContainer-Container {
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-start;
    /* background-color: #6C6C6C; */
    padding-bottom: 2rem;
}

.TravelContainer-ImageContainer {
    /* height: 400px;
    width: 400px;
    background-color: blue; */
    display: flex;
    justify-content: center;
    border-radius: 50px;
}

.TravelContainer-Image {
    height: 400px;
    /* width: 400px; */
    /* height: 100%;
    width: 100%; */
    /* background-color: transparent; */
    border-radius: 50px;
}

.TravelContainer-ImageFill {
    height: 400px;
    border-radius: 50px;
    object-fit: cover;
    /* width: 100%;
    height: 100%; */
    /* object-fit: cover; */
}

.TravelContainer-ImageFill{
    /* object-fit: cover; */
    z-index: 0 !important;
    border-radius: 50px;
}

.Carousel-Contain {
    /* width: 450px;
    height: 450px; */
    /* object-fit: cover; */
}

.Carousel-Contain img {
    height: 400px !important;
    width: 100%;
    object-fit: cover;
    border-radius: 50px;
}

.carousel{
    width:100%;
    height: 400px;
  }

/* img{
    object-fit:cover !important;
    height:300px !important;
    width:350px !important;
  } */


