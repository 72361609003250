.ContactPage-Container {
    text-align: left;
    padding-bottom: 5rem;
    /* justify-content: start; */
    align-items: baseline;
}

.ContactPage-Container h1 {
    font-size: 100px;
    font-family: "Playfair Display";
    color: black;
}

.hover-2 {
    color: black !important;
    text-decoration: none !important;
    padding: 1rem;
    background: 
      linear-gradient(#c1be10 0 0) no-repeat
      var(--p, 200%) 100% / 200% var(--p, .08em);
    transition: .3s var(--t, 0s), background-position .3s calc(.3s - var(--t, 0s));
  }
  .hover-2:hover {
    --p: 100%;
    --t: .3s;
    color: white !important;
  }