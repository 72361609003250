.LandingPage-App {
    min-height: 90vh;
    min-width: 50vh;
    /* display: flex; */
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}

.LandingPage-Card {
    flex-direction: row !important;
    background-color: #EEEEEE !important;
    justify-content: center !important;
}

.LandingPage-Picture {
    width: 50%;
    height: 420px;
    /* background: linear-gradient(180deg, rgba(254,222,206,1) 0%, rgba(254,222,206,1) 25%, rgba(255,255,255,1) 50%); */
}


.LandingPage-Row {
    display: flex;
    align-items: center;
}

.LandingPage-Body {
    margin-left: 4rem;
    padding-left: 4rem !important;
    border-style: none none none solid;
}

.card.LandingPage-Card {
    /* B0CCC0 */
    background-color: white;
    margin: 50px;
    padding: 50px;
    justify-content: center;
    text-align: left;
    font-size: 30px;
    border: none;
}

.card.LandingPage-Card.h2 {
    font-size: 50px;
}