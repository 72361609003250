.App {
  background-color: #EEEEEE;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-Container {
  text-align: left;
  align-items: flex-start;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.Title {
  color: #0F0F0F;
  text-transform: lowercase;
  font-weight: 900;
  font-size: calc(100px + 2vmin);
}

.Subtitle {
  color: white;
  text-transform: lowercase;
  font-size: calc(50px + 2vmin);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
